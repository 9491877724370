
import { notification } from "antd";
import { mainAxios } from "axoisInstance";
import { FETCH_USER_DETAILS_ERROR, FETCH_USER_DETAILS_REQUESTED, FETCH_USER_DETAILS_SUCCESS,  LOGIN_USER_ERROR, LOGIN_USER_REQUESTED, LOGIN_USER_SUCCESS } from "./actionTypes";


export const fetchAllUserDetails = (clientTypeSearch, clientSearchValue) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FETCH_USER_DETAILS_REQUESTED,
    });
    const response = await mainAxios.get(
      `${
        process.env.REACT_APP_PICKRR_V1
      }/users/internal/jwt/data?${clientTypeSearch}=${clientSearchValue}`
    );
    if (response.data.err) {
      notification.error({
        message: response.data.err
      })
      dispatch({
        type: FETCH_USER_DETAILS_ERROR,
        payload: response.data.err,
      });
    } else {
      dispatch({
        type: FETCH_USER_DETAILS_SUCCESS,
        payload: response.data,
      });
    }
  }catch(error){
    notification.error({
      message: error.response.data.err
    })
    dispatch({
      type: FETCH_USER_DETAILS_ERROR,
      payload: error.response.data.err,
    });
  }
  };


export const fetchUserAuthToken = (id) => async (dispatch, getState) => {
    try{
      dispatch({
        type: LOGIN_USER_REQUESTED,
      });
      const response = await mainAxios.get(
        `${
          process.env.REACT_APP_PICKRR_V1
        }/users/internal/jwt/token?user_conf_pk=${id}`
      );
      if (response.data.err) {
        notification.error({
          message: response.data.err
        })
        dispatch({
          type: LOGIN_USER_ERROR,
          payload: response.data.err,
        });
      } else {
        dispatch({
          type: LOGIN_USER_SUCCESS,
          payload: response.data,
        });
        if(Object.keys(response.data.data).length){
          window.open(
            `https://dashboard.pickrr.com/?customer_auth_token=${response?.data?.data?.super_user_auth_token}&customer_user_role_auth_token=${response?.data?.data?.auth_token}&customer_token=${response?.data?.data?.token}`
          )
        }
      }
    }catch(error){
      notification.error({
        message: error.response.data.err
      })
      dispatch({
        type: LOGIN_USER_ERROR,
        payload: error.response.data.err,
      });
    }
  };  