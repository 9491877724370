import React from 'react';
import GoogleButton from 'react-google-button'

import { PickrrCard } from 'components/UIElements';
import { Title } from './style';


const LoginFormCard = () => {
  return (
    <PickrrCard style={{ minWidth: '502px' }}>
      <Title>Login</Title>
        <a href="https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=720231151758-eff70tq1981fiklsbhs0sf1rl6bdsoq4.apps.googleusercontent.com&redirect_uri=https://pickrr.com/users/internal/google/login/&prompt=select_account&access_type=offline&scope=https://www.googleapis.com/auth/userinfo.email+https://www.googleapis.com/auth/userinfo.profile">
          <GoogleButton style={{width: '100%'}}/>
        </a>
    </PickrrCard>
  );
};

export default LoginFormCard;
