import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import MasterLayout from './components/MasterLayout';
import { useSelector } from 'react-redux';

export const AuthRoute = ({ component: Component, ...rest }) => {
  const user = useSelector(
    ({ user }) => user
  );
  return (
    <Route
      {...rest}
      render={(props) =>
        user ? (
          <Redirect
            to={{
              pathname: '/all-users',
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const  user = useSelector(
    ({ user }) => user
  );
  return (
    <Route
      {...rest}
      render={(props) =>
        user ? (
          <MasterLayout>
            <Component {...props} />
          </MasterLayout>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
            }}
          />
        )
      }
    />
  );
};
