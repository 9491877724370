import axios from 'axios';

//let jwtToken = localStorage.getItem('jwt_auth_token');

const setHeaderAuthToken = (token) => {
  if (token) {
    mainAxios.defaults.headers.common['authorization'] = token;

  } else {
    delete mainAxios.defaults.headers.common['authorization'];
  }
};

const mainAxios = axios.create({
  baseURL: 'https://pickrr.com',
  // headers: { authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiIxIiwiZXhwIjoxNjQzMzYzMzMzLCJ0ZW0iOiJwcml5YUBwaWNrcnIuY29tIn0.q7PZfuvbYG1ZFctcvN4TKl3AhGtcZimcDCrMTAxmMQE' },
});

mainAxios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    if (error?.response?.status === 401) {
      localStorage.removeItem('auth_token');
      window.location.replace('/login');
      return;
    }
    return Promise.reject(error);
  }
);


export default setHeaderAuthToken;

// export { mainAxios, profileAxios };
export { mainAxios };
