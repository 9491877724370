import React from 'react';

import { FlexContainer } from 'components/UIElements';
import LoginFormCard from 'components/Login/LoginFormCard';

const Login = () => {
  return (
    <div style={{ background: '#E5E5E5', minHeight: '100vh' }}>
      <div
        style={{
          background: '#263f97',
          height: '386px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <img
          src="/pickrr-logo-white.svg"
          alt="logo"
          style={{ width: '180px', marginTop: '-100px' }}
        />
      </div>
      <FlexContainer style={{ marginTop: '-100px' }}>
        <LoginFormCard />
      </FlexContainer>
    </div>
  );
};

export default Login;
