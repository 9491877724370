import React, { useState, useEffect } from "react";
import { Layout, Menu, Row, Col, Dropdown } from "antd";
import { Link, useLocation } from "react-router-dom";
import {
  LogoutOutlined,
  UserOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";

import { MasterLayoutStyle, LogoContainer, ContentX } from "./style";
import { FlexEndContainer, PickrrButton } from "../UIElements";
import { useDispatch } from "react-redux";
import { logout } from "redux/actions/userActions";

const { Header, Footer, Sider } = Layout;

const MasterLayout = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [defaultSelectedKey, setDefaultSelectedKey] = useState("");
  const collapsed = false;

  useEffect(() => {
    setDefaultSelectedKey(location.pathname);
  }, [location.pathname]);

  const profileMenu = (
    <Menu style={{ padding: 4 }}>
      <Menu.Item
        key="/logout"
        onClick={() => dispatch(logout(true))}
        icon={<LogoutOutlined />}
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout>
      <MasterLayoutStyle />
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{
          backgroundColor: "#263f97",
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
        }}
      >
        <LogoContainer>
          {collapsed ? (
            <img
              src="https://res.cloudinary.com/pickrr/image/upload/v1616403457/Sales%20App/ws-logo_cseakc.svg"
              style={{
                height: "32px",
                margin: "16px 10px",
              }}
              alt="logo"
            />
          ) : (
            <img
              src="https://res.cloudinary.com/pickrr/image/upload/v1616403457/Sales%20App/w-logo_dgilt3.svg"
              style={{
                height: "32px",
                margin: "16px 10px",
              }}
              alt="logo"
            />
          )}
        </LogoContainer>
        <Menu
          theme="light"
          mode="inline"
          defaultOpenKeys={["manage-users"]}
          selectedKeys={[defaultSelectedKey]}
        >
          <Menu.Item
            key="/all-users"
            icon={<UnorderedListOutlined style={{ color: "#FFF" }} />}
          >
            <Link to="/all-users">All Users</Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout
        style={{
          marginLeft: collapsed ? "80px" : "200px",
          transition: "all 0.2s",
        }}
      >
        <Header
          style={{
            padding: "0 24px",
            background: "#fff",
            boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.06)",
          }}
        >
          <Row gutter={16} style={{ height: "64px", float: "right" }}>
            <Col span={8}>
              <FlexEndContainer
                style={{ alignItems: "center", height: "64px" }}
              >
                <a href="https://pricing.pickrr.com">
                  <PickrrButton color="primary" className="mr-12">
                    Pricing App Login
                  </PickrrButton>
                </a>
                <Dropdown overlay={profileMenu} placement="bottomRight" arrow>
                  <UserOutlined
                    style={{
                      color: "#263F97",
                      fontSize: "24px",
                      cursor: "pointer",
                    }}
                  />
                </Dropdown>
              </FlexEndContainer>
            </Col>
          </Row>
        </Header>
        <ContentX>{children}</ContentX>

        <Footer style={{ textAlign: "center" }}>
          Pickrr Technologies Pvt. Ltd. All Rights Reserved ©2020
        </Footer>
      </Layout>
    </Layout>
  );
};

export default MasterLayout;
