export const getUrlParams = () => {
    try {
      const params = {};
      window.location.href
        .split('?')[1]
        .split('&')
        .forEach(function (i) {
          params[i.split('=')[0]] = i.split('=')[1];
        });
      return params;
    } catch {
      return {};
    }
  };