import styled, { createGlobalStyle } from 'styled-components';
import { Layout } from 'antd';

const { Content } = Layout;

export const MasterLayoutStyle = createGlobalStyle`
  .ant-layout, .ant-layout-footer{
      background: #edf0f9;
    }

  .trigger {
      line-height: 64px;
      cursor: pointer;
      transition: color 0.3s;
      color: #263f97;
    }
  /* .trigger:hover {
      color: #e42536;
    } */
  .site-layout-background {
    background: #fff;
  }

  .ant-menu-root.ant-menu-inline, .ant-menu-root.ant-menu-vertical{
    background: #263f97;
  }
  .ant-menu-item a, .ant-menu-item a:hover{
    color: #fff;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected, .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected:hover{
    background-color: rgba(255, 255, 255, 0.08);
    color: #fff;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-active{
    color: unset;
  }
  .ant-menu-inline .ant-menu-item::after{
    border-color: #ff756c;
    border-width: 7px;
  }
  .ant-menu-inline, .ant-menu-vertical{
    border-right: 0;
  }
  .ant-menu-item-selected a, .ant-menu-item-selected a:hover, .ant-menu-item-selected svg{
    color: #fff;
    fill: #fff;
  }
  .ant-menu-submenu-title{
    color: #fff;
  }
  .ant-menu-submenu-title:hover{
    color: #fff;
  }
  .ant-menu-sub.ant-menu-inline{
    background: #263f97;
    color: #fff;
  }
  .ant-menu-submenu-popup > .ant-menu{
    background: #263f97;
  }
  .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before, .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before, .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before, .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::before, .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after, .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after, .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after, .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow::after{
    background: #fff !important;
  }

  .ant-menu-submenu-selected > .ant-menu-submenu-title {
    color: #0A2583;
    background: #F3F8FC;
  }

  .ant-menu-submenu-selected > .ant-menu-submenu-title .ant-menu-submenu-arrow::before, .ant-menu-submenu-selected > .ant-menu-submenu-title .ant-menu-submenu-arrow::after{
    background: #0A2583 !important;
  }

  .ant-menu-submenu-selected > .ant-menu-submenu-title svg{
    color: #0A2583;
  }

  .menu-icon{
    font-size: 18px;
    color: #fff;
  }
`;

export const ContentX = styled(Content)`
  min-height: calc(100vh - 158px);
  margin: 24px 24px 0;
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NavLinks = styled.span`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 20px;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => (props.color === 'red' ? '#e42536' : '#303b91')};
  cursor: pointer;
  svg {
    fill: ${(props) => (props.color === 'red' ? '#e42536' : '#303b91')};
  }
  letter-spacing: 0.2px;
  display: flex;
  align-items: center;
  /* :hover {
    color: #e42536;
  }
  :hover svg {
    fill: #e42536;
  } */
`;

export const SelectContainer = styled.div`
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: #ebedf7;
    color: #263f97;
  }
  .ant-select-arrow {
    color: #263f97;
  }
`;

export const SearchContainer = styled.div`
  .ant-input-affix-wrapper,
  .ant-picker-range,
  .ant-select-selector.ant-btn,
  .ant-input {
    border-radius: 0 !important;
    background: #ebedf7;
  }
  .ant-input-affix-wrapper > input.ant-input {
    background: #ebedf7;
  }
  .ant-input-prefix {
    color: #263f97;
  }
  .ant-input-placeholder {
    color: #263f97 !important;
  }
`;
