import styled from 'styled-components';
import { Button, Card, Select, Input } from 'antd';

export const PickrrButton = styled(Button)`
  background-color: ${(props) => {
    switch (props.color) {
      case 'primary':
        return '#263F97';
      case 'secondary':
        return 'transparent';
      case 'grey':
        return '#808080';
      case 'peach':
        return '#ff756c';
      case 'cancel':
        return '#EDF0F9';
      case 'outline':
        return '#EDF0F9';
      case 'white':
        return '#fff';
      case 'white-red':
        return '#fff';
      default:
        return '#263F97';
    }
  }} !important;
  border-color: ${(props) => {
    switch (props.color) {
      case 'primary':
        return '#263F97';
      case 'secondary':
        return '#808080';
      case 'grey':
        return '#808080';
      case 'peach':
        return '#ff756c';
      case 'cancel':
        return '#EDF0F9';
      case 'outline':
        return '#C5CDE3';
      case 'white':
        return '#fff';
      case 'white-red':
        return '#fff';
      default:
        return '#263F97';
    }
  }} !important;
  color: ${(props) => {
    switch (props.color) {
      case 'secondary':
        return '#808080';
      case 'cancel':
        return '#7D8CC1';
      case 'outline':
        return '#263F97';
      case 'white':
        return '#263F97';
      case 'white-red':
        return '#DE1306';
      default:
        return '#fff';
    }
  }} !important;
  font-weight: ${(props) => {
    switch (props.weight) {
      case 'bold':
        return 'bold';
      case 'normal':
        return 'normal';
      default:
        return 'normal';
    }
  }} !important;
  font-size: 14px;
  line-height: 24px;
  border-radius: 8px;
  letter-spacing: 0.12px;
  &:disabled {
    background-color: #808080 !important;
    border-color: #edf0f9 !important;
    color: #fff !important;
  }
`;
export const SpaceBetweenContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: ${(props) => {
    switch (props.direction) {
      case 1:
        return 'column';

      default:
        return 'row';
    }
  }};
`;
export const SpaceEvenlyContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: ${(props) => {
    switch (props.direction) {
      case 1:
        return 'column';

      default:
        return 'row';
    }
  }};
`;
export const FlexColContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlexBox = styled.div`
  display: flex;
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FlexEndContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const PickrrHeading = styled.p`
  font-weight: bold;
  font-size: ${(props) => {
    switch (props.size) {
      case 1:
        return '24px';
      case 2:
        return '20px';
      case 3:
        return '18px';
      default:
        return '24px';
    }
  }};
  color: #263f97;
  margin-bottom: 0px;
`;

export const PickrrCard = styled(Card)`
  border-radius: 15px;
  background: #fff;
  padding: 30px;
`;

export const CustomSelect = styled(Select)`
  border: 1px solid #c5cde3;
  border-radius: 5px;

  .ant-select-selection-placeholder,
  .ant-select-arrow {
    color: #263f97;
  }
`;

export const CustomInput = styled(Input)`
  border-color: #dfe0eb;
  border-radius: 5px;
  .ant-picker-input input::placeholder {
    color: rgba(126, 143, 204, 0.7);
  }
  .ant-input-group-addon:first-child {
    background-color: #ebedf7;
  }
  .ant-input-group-addon {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #263f97;
  }
  .ant-input-group.ant-input-group-compact > *:first-child,
  .ant-input-group.ant-input-group-compact
    > .ant-select:first-child
    > .ant-select-selector,
  .ant-input-group.ant-input-group-compact
    > .ant-select-auto-complete:first-child
    .ant-input,
  .ant-input-group.ant-input-group-compact
    > .ant-cascader-picker:first-child
    .ant-input {
    background: #fff;
  }
`;
export const CustomInputPassword = styled(Input.Password)`
  border-color: #dfe0eb;
  border-radius: 5px;
  .ant-picker-input input::placeholder {
    color: rgba(126, 143, 204, 0.7);
  }
  .ant-input-group-addon:first-child {
    background-color: #ebedf7;
  }
  .ant-input-group-addon {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #263f97;
  }
  .ant-input-group.ant-input-group-compact > *:first-child,
  .ant-input-group.ant-input-group-compact
    > .ant-select:first-child
    > .ant-select-selector,
  .ant-input-group.ant-input-group-compact
    > .ant-select-auto-complete:first-child
    .ant-input,
  .ant-input-group.ant-input-group-compact
    > .ant-cascader-picker:first-child
    .ant-input {
    background: #fff;
  }
`;
