import React from "react";
import { Row, Col } from "antd";

import { Container, DetailHeading, DetailSubheading } from "./style";
import { PickrrButton } from "components/UIElements";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserAuthToken } from "redux/actions/userDetails";

const UserFullDetails = () => {
  const dispatch = useDispatch();

  const { userDetailsData } = useSelector(
    ({ userDetails }) => userDetails
  );

  const {user_name ="", company_name="", user_conf_pk, user_email, mobile_number =""} = userDetailsData;

  return (
    !!Object.keys(userDetailsData).length && (
      <Container>
        <Row gutter={[16,24]}>
          <Col xl={5} lg={5} md={5} sm={12} xs={12}>
            <DetailHeading>User Name</DetailHeading>
            <DetailSubheading>{user_name}</DetailSubheading>
          </Col>
          <Col xl={5} lg={5} md={5} sm={12} xs={12}>
            <DetailHeading>User Email Address</DetailHeading>
            <DetailSubheading>{user_email}</DetailSubheading>
          </Col>
          <Col xl={5} lg={5} md={5} sm={12} xs={12}>
            <DetailHeading>Mobile Number</DetailHeading>
            <DetailSubheading>{mobile_number}</DetailSubheading>
          </Col>
          <Col xl={5} lg={5} md={5} sm={12} xs={12}>
            <DetailHeading>Company Name</DetailHeading>
            <DetailSubheading>{company_name}</DetailSubheading>
          </Col>
          <Col xl={4} lg={4} md={4} sm={12} xs={12}>
            <DetailHeading>Action</DetailHeading>
            <DetailSubheading>
            <PickrrButton
              onClick={() =>
                dispatch(fetchUserAuthToken(user_conf_pk))
              }
            >
              Login
            </PickrrButton>
            </DetailSubheading>
          </Col>
        </Row>
      </Container>
    )
  );
};

export default UserFullDetails;
