import styled from 'styled-components';

export const Title = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 28px;
  color: #263f97;
  margin-bottom: 50px;
  text-align: center;
`;

export const Placeholder = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #263f97;
`;
