import React, { useState } from "react";
import { Input, notification, Select } from "antd";

import { PickrrHeading } from "components/UIElements";
import { Container } from "./style";
import { useDispatch } from "react-redux";
import { fetchAllUserDetails } from "redux/actions/userDetails";
import UserFullDetails from "components/ManageUsers";

const { Search } = Input;
const { Option } = Select;

const AllUsers = () => {
  const [clientInformation, setClientInformation] = useState("client_token");
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();

  const fetchUsers = (value) => {
    if (value.length && clientInformation.length) {
      dispatch(fetchAllUserDetails(clientInformation, value));
    } else {
      notification.error({
        message: "Kindly enter details which you want to search",
      });
    }
  };
  const searchOnChange = (e) => {
    setSearchText(e.target.value);
  };
  const handleChange = (value) => {
    setClientInformation(value);
  };
  return (
    <Container>
      <PickrrHeading className="mb-16">All Users</PickrrHeading>
      <Select
        //defaultValue={clientInformation}
        style={{ width: 220, marginRight: 12 }}
        onChange={handleChange}
        placeholder="Select User Information"
        defaultValue={clientInformation}
        allowClear
      >
        {/* <Option value="client_email">Email Id</Option> */}
        <Option value="client_token">Auth Token</Option>
      </Select>
      <Search
        placeholder="Enter User Details"
        enterButton
        style={{ maxWidth: "350px" }}
        className="mb-16"
        allowClear
        onSearch={fetchUsers}
        onChange={searchOnChange}
        value={searchText}
      />
      <UserFullDetails />
    </Container>
  );
};

export default AllUsers;
