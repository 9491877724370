import {
    SET_USER,
    LOGOUT,
  } from '../actions/userActions/actionTypes';
  
  const userReducer = (state = localStorage.getItem('auth_token'), action) => {
    const { type, payload } = action;
    switch (type) {
      case SET_USER:
        return payload;
  
      case LOGOUT:
        return null;
  
      default:
        return state;
    }
  };
  
  export default userReducer;
  