import "./App.css";
import React, { useEffect } from "react";
import { Switch, useHistory } from "react-router-dom";

import ScrollToTop from "ScrollToTop";
import { PrivateRoute, AuthRoute } from "routes";
import AllUsers from "pages/ManageUsers/AllUsers";
import Login from "pages/Login";
import { useDispatch, useSelector } from "react-redux";
import { setLoginUserToken, logout } from "./redux/actions/userActions";
import { getUrlParams } from "utils";
import setHeaderAuthToken from "axoisInstance";

function getFaviconEl() {
  return document.getElementById("favicon");
}
function App() {
  const handleFavIcon = () => {
    const favicon = getFaviconEl();
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      favicon.href =
        "https://d10srchmli830n.cloudfront.net/1619692877707_favicon-white.svg";
    } else {
      favicon.href =
        "https://d10srchmli830n.cloudfront.net/1619692800885_favicon.svg";
    }
  };

  const dispatch = useDispatch();
  const history = useHistory();
  const userAuthToken = useSelector(({ user }) => user);

  useEffect(() => {
    handleFavIcon();
  }, []);

  useEffect(() => {
    const { auth } = getUrlParams() || {};
    if (auth) {
      localStorage.setItem("auth_token", auth);
      dispatch(setLoginUserToken(auth));
      setHeaderAuthToken(auth);
      let cookieDate = new Date();
      cookieDate.setTime(cookieDate.getTime() + 2000 * 24 * 60 * 60 * 1000);
      document.cookie = `jwq=${encodeURIComponent(
        auth
      )};domain=.pickrr.com;path=/;expires=${cookieDate}`;
      history.replace("/all-users");
    }
  }, [history, dispatch]);

  useEffect(() => {
    if (!localStorage.getItem("auth_token")) {
      dispatch(logout());
      history.replace("/login");
    } else {
      dispatch(setLoginUserToken(localStorage.getItem("auth_token")));
      let cookieDate = new Date();
      cookieDate.setTime(cookieDate.getTime() + 2000 * 24 * 60 * 60 * 1000);
      document.cookie = `jwq=${encodeURIComponent(
        userAuthToken
      )};domain=.pickrr.com;path=/;expires=${cookieDate}`;
      history.replace("/all-users");
    }
  }, [dispatch, history, userAuthToken]);

  useEffect(() => {
    if (userAuthToken !== null) {
      setHeaderAuthToken(userAuthToken);
    }
  }, [userAuthToken]);

  return (
    <ScrollToTop>
      <Switch>
        {userAuthToken ? (
          <PrivateRoute exact path="/all-users" component={AllUsers} />
        ) : (
          <AuthRoute exact path="/login" component={Login} />
        )}
      </Switch>
    </ScrollToTop>
  );
}

export default App;
