import { LOGOUT, SET_USER } from "./actionTypes";

export const setLoginUserToken = (user) => {
  return {
    type: SET_USER,
    payload: user,
  };
};

export const logout = (replaceRoute = false) => {
  localStorage.removeItem("auth_token");
  document.cookie =
    "jwq=;domain=.pickrr.com;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  if (replaceRoute) {
    window.location.replace("/login");
    //window.location.reload();
  }
  return {
    type: LOGOUT,
  };
};
