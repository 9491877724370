import styled from 'styled-components';

export const Container = styled.div`
  .ant-input-search
    > .ant-input-group
    > .ant-input-group-addon:last-child
    .ant-input-search-button {
    background: #263f97;
    border-color: #263f97;
  }
`;
