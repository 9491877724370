import { FETCH_USER_DETAILS_ERROR, FETCH_USER_DETAILS_REQUESTED, FETCH_USER_DETAILS_SUCCESS } from "redux/actions/userDetails/actionTypes";


const INITIAL_STATE = {
    fetchingUserDetails: false,
    userDetailsData: {},
    userDetailsError: null,
}

const userDetailsReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
      case FETCH_USER_DETAILS_REQUESTED:
        return {
          ...state,
          fetchingUserDetails: true,
          userDetailsError: null
        };
      case FETCH_USER_DETAILS_SUCCESS:
        return {
          ...state,
          fetchingUserDetails: false,
          userDetailsData: payload.data,

        };
      case FETCH_USER_DETAILS_ERROR:
        return {
          ...state,
          userDetailsError: payload,
          fetchingUserDetails: false
        };
  
      default:
        return state;
    }
  };
  
  export default userDetailsReducer;